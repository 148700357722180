<template>
  <vx-card class="security-setting-page main-box md:w-1/2 top-zero-radius">
    <vs-row>
      <vs-col class="w-1/2">
        انتخاب تم مورد نظر
      </vs-col>

      <vs-col class="w-1/2">
        <custom-select :default="themeMode"
                       :options="themes"
                       v-model="themeMode"
        />
      </vs-col>
    </vs-row>

    <vs-button id="saveSecuritySettingBTN" class="useral-action-button" @click="setTheme"/>
  </vx-card>
</template>

<script>
import CustomSelect from "../../../../components/customSelect/customSelect";
export default {
  name: "customizeSetting",
  components: {CustomSelect},

  data () {
    return {
      themeMode: {
        label: '-',
        value: ''
      },
      themes: [
        {
          label: 'Flamingo',
          value: 'flamingo'
        },
        {
          label: 'Sea',
          value: 'sea'
        },
        {
          label: 'Semi Dark',
          value: 'semi-dark'
        },
        {
          label: 'Dark',
          value: 'dark'
        },
        {
          label: 'Light',
          value: 'light'
        },
      ],
      actions: {
        toolbar: [
          {
            id: 'saveSecuritySettingBTN',
            icon: 'icon-check',
            iconPack: 'feather',
            color: 'success'
          }
        ],
        leftToolbar: [
          {
            id: {name: 'Settings'},
            type: 'link',
            icon: 'icon-chevron-left',
            iconPack: 'feather'
          }
        ]
      }
    }
  },

  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
    }, 50)

    let themeRow = -1
    const localTheme = localStorage.getItem('theme')
    if (localTheme) {
      let themesName = this.themes.map(item => {
        return item.value
      })
      themeRow = themesName.indexOf(localTheme)
      if (themeRow > -1) {
        this.themeMode = this.themes[themeRow]
      }
    }

  },

  // watch: {
  //   themeMode: {
  //     handler (val) {
  //       this.$store.dispatch('updateTheme', val.value)
  //     }
  //   }
  // },

  methods: {
    setTheme () {
      const payload = {
        value: this.themeMode.value,
        sendServer: true
      }
      this.$store.dispatch('updateTheme', payload)
    }
  }
}
</script>

<style scoped>

</style>
